.heater-fuel-consumption-container {
  padding: 1rem;
}

.heater-fuel-consumption-title {
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.heater-fuel-consumption-title-text {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
}

.heater-fuel-consumption-timestamp {
  position: absolute;
  right: 0;
  font-size: 1.2rem;
}

.heater-fuel-consumption-content:not(:last-child) {
  margin-bottom: 2rem;
}

@media screen and (max-width: 1024px) {
  .heater-fuel-consumption-title {
    position: static;
    flex-direction: column;
  }

  .heater-fuel-consumption-timestamp {
    position: static;
    right: auto;
  }
}
