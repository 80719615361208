.daily-image-slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.daily-image-slider-title {
  padding: 0.6rem 0;
  font-size: 1.2rem;
  font-weight: bold;
}

.daily-image-slider-input-area {
  padding-bottom: 1rem;
}

.daily-image-slider-content {
  width: 100%;
  display: flex;
  justify-content: center;
}

.daily-image-slider-slider-area {
  width: 48%;
}

.daily-image-slider-slide-timestamp {
  padding: 0.5rem 0;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
}

@media screen and (max-width: 1024px) {
  .daily-image-slider-slider-area {
    width: 60%;
  }
}
