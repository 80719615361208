.image-analysis-past-plot-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-analysis-past-plot-title {
  padding-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-analysis-past-plot-input-area {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.image-analysis-past-plot-input-item {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
}

.image-analysis-past-plot-input-item > p {
  flex-basis: 5.3rem;
  flex-shrink: 0;
}

.image-analysis-past-plot-button-area {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 1rem;
}

.image-analysis-past-plot-button-area > button {
  width: 8rem;
  height: 2.4rem;
}

.image-analysis-past-plot-plot-area {
  width: 100%;
}
