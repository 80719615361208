.current-value-container {
  padding: 1rem;
}

.current-value-title {
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.current-value-title-icon {
  width: 2.7rem;
  padding-right: 0.6rem;
}

.current-value-title-text {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
}

.current-value-timestamp {
  position: absolute;
  right: 0;
  font-size: 1.2rem;
}

.current-value-content {
  display: flex;
}

.current-value-list-area {
  width: 50%;
}

.current-value-list-area > ul {
  display: flex;
  flex-wrap: wrap;
  row-gap: 2.2rem;
}

.current-value-list-area > ul > li {
  width: calc(100%/2);
}

@media screen and (max-width: 1024px) {
  .current-value-title {
    position: static;
    flex-direction: column;
  }

  .current-value-timestamp {
    position: static;
    right: auto;
  }

  .current-value-list-area > ul > li {
    width: 100%;
  }
}
