.image-analysis-current-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.image-analysis-current-update-area {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
}

.image-analysis-current-update-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.image-analysis-current-update-button > label {
  width: 100%;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  background-color: #7fbfff;
  cursor: pointer;
  border-radius: 28px;
  border: 0;
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.6rem;
  transition: 0.3s;
}

.image-analysis-current-update-image {
  width: 60%;
}

.image-analysis-current-analysis-button {
  width: 120px;
  height: 50px;
  border-radius: 10px;
  cursor: pointer;
}

.image-analysis-current-loading-area {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-analysis-current-loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.image-analysis-current-result-area {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.image-analysis-current-result-title {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.image-analysis-current-result-title-text {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
}

.image-analysis-current-sp2-info-list-area > ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.image-analysis-current-sp2-info-list-item-container {
  display: flex;
  width: 25%;
}

.image-analysis-current-sp2-info-list-item-icon {
  flex: 1;
  display: flex;
  align-items: center;
  padding-right: 1rem;
}

.image-analysis-current-sp2-info-list-item-content {
  flex: 3;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.image-analysis-current-sp2-info-list-item-value {
  font-size: 2rem;
}

.image-analysis-current-sp2-info-list-item-value-unit {
  font-size: 1rem;
}

.image-analysis-current-sp2-images-area {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.image-analysis-current-sp2-image-area {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-bottom: 20px;
}

@media screen and (max-width: 1024px) {
  .image-analysis-current-update-area {
    gap: 30px;
  }

  .image-analysis-current-update-image {
    width: 90%;
  }

  .image-analysis-current-sp2-info-list-area > ul {
    row-gap: 2.2rem;
  }

  .image-analysis-current-sp2-info-list-item-container {
    width: 50%;
  }

  .image-analysis-current-sp2-image-area {
    width: 90%;
  }
}
