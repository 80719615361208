.sp2-info-container {
  padding: 1rem;
}

.sp2-info-title {
  padding-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sp2-info-title-icon {
  width: 2.7rem;
  padding-right: 0.6rem
}

.sp2-info-content {
  display: flex;
}

.sp2-info-list-area {
  flex: 4;
}

.sp2-info-crop-area {
  flex: 1;
}

@media screen and (max-width: 1024px) {
  .sp2-info-crop-area {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
