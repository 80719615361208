.monthly-image-calendar-modal {
  position: relative;
}

.monthly-image-calendar-modal-close-button {
  position: absolute;
  right: 1.4rem;
  font-size: 4rem;
  color: gray;
  cursor: pointer;
}

.monthly-image-calendar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.monthly-image-calendar-input-area {
  padding: 1rem 0;
}

.monthly-image-calendar-input-select-camera {
  display: flex;
  align-items: center;
}

.monthly-image-calendar-input-search-month {
  display: flex;
  align-items: center;
}

.monthly-image-calendar-input-search-month > p {
  flex-shrink: 0;
}

.monthly-image-calendar-content {
  width: 100%;
  display: flex;
  justify-content: center;
}

.monthly-image-calendar {
  width: 100%;
  margin-bottom: 4rem;
}

.monthly-image-calendar-header {
  display: flex;
}

.monthly-image-calendar-header > li {
  width: calc(100% / 7);
  text-align: center;
}

.monthly-image-calendar-dates {
  display: flex;
  flex-wrap: wrap;
}

.monthly-image-calendar-dates > li {
  width: calc(100% / 7);
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.monthly-image-calendar-thumbnail-area {
  width: 90%;
  min-height: 133px;
}
