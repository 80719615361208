.co2-concentration-change-container {
  border: solid 3px rgb(41, 171, 226);
}

.co2-concentration-change-title {
  padding: 10px;
  background-color: rgb(41, 171, 226);
  display: flex;
  justify-content: center;
  align-items: center;
}

.co2-concentration-change-title-text {
  font-size: 1.4rem;
  font-weight: bold;
  color: white;
}

.co2-concentration-change-content {
  padding: 2rem 1rem;
  display: flex;
}

.co2-concentration-change-value-area {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.co2-concentration-change-changed-value,
.co2-concentration-change-real-value {
  display: flex;
}

.co2-concentration-change-changed-value > li,
.co2-concentration-change-real-value > li {
  width: 50%;
}


.co2-concentration-change-arrow-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.co2-concentration-change-arrow-square {
  width: 3rem;
  height: 3rem;
  background-color: rgb(41, 171, 226);
}

.co2-concentration-change-arrow-triangle-top {
  width: 0;
  height: 0;
  border-style: solid;
  border-right: 3rem solid transparent;
  border-left: 3rem solid transparent;
  border-bottom: 3rem solid rgb(41, 171, 226);
  border-top: 0;
}

.co2-concentration-change-arrow-triangle-bottom {
  width: 0;
  height: 0;
  border-style: solid;
  border-right: 3rem solid transparent;
  border-left: 3rem solid transparent;
  border-top: 3rem solid rgb(41, 171, 226);
  border-bottom: 0;
}

.co2-concentration-change-plot-area {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.co2-concentration-change-plot-label-area {
  text-align: center;
}

.co2-concentration-change-plot-label {
  font-weight: bold;
  font-size: 1.4rem;
}

.co2-concentration-change-plot-value {
  font-weight: bold;
  font-size: 1.2rem;
}

.co2-concentration-change-plot {
  width: 100%;
}

.co2-concentration-change-difference {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
}

.co2-concentration-change-difference-down {
  font-size: 1.6rem;
  color: rgb(41, 171, 226);
}

.co2-concentration-change-difference-up {
  font-size: 1.6rem;
  color: red;
}

@media screen and (max-width: 1024px) {

  .co2-concentration-change-content {
    flex-direction: column;
    row-gap: 3rem;
  }

  .co2-concentration-change-value-area,
  .co2-concentration-change-plot-area {
    width: 100%;
  }

  .co2-concentration-change-value-area {
    row-gap: 2rem;
  }
}

@media screen and (max-width: 450px) {
  .co2-concentration-change-plot {
    height: 300px;
  }
}
