.sp2-info-list {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.sp2-info-list > li {
  width: 50%;
}

@media screen and (max-width: 1024px) {
  .sp2-info-list {
    row-gap: 1rem;
  }

  .sp2-info-list > li {
    width: 100%;
  }
}
