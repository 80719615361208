.image-analysis-past-image-slider-image-container {
  display: flex; 
  flex-direction: row;
  justify-content: center; 
  align-items: center;
}

.image-analysis-past-image-slider-image-container > div {
  width: 50%;
}

@media screen and (max-width: 1024px) {
  .image-analysis-past-image-slider-image-container {
    flex-direction: column;
  }

  .image-analysis-past-image-slider-image-container > div {
    width: 100%;
  }
}
