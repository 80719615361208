.header {
  height: 62px;
}

.header-content {
  width: 100%;
  height: 62px;
  background-color: #009245;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #FFFFFF;
  position: relative;
}

.header-content > h1 {
  padding: 0.5rem 0 0.5rem 1rem;;
  font-size: 1.8rem;
}

.header-menu {
  position: absolute;
  top: 62px;
  right: 0;
  background-color: #019244;
  width: 12rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header-menu > li {
  border-top: solid 1px white;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 56px;
}

.header-menu-button {
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.header-menu-button:hover {
  cursor: pointer;
}

.user-name-label {
  font-size: 1.2rem;
  font-weight: bold;
}

.logout-button {
  background-color: #019244;
  border-width: 0;
  color: #FFFFFF;
  font-size: 1rem;
  font-weight: bold;
}

.logout-button:hover {
  cursor: pointer;
}

.globalnav-button {
  display: none;
}

@media screen and (max-width: 1024px) {
  .header-content {
    position: fixed;
    z-index: 9;
  }

  .globalnav-button {
    display: block;
    width: 50px;
    height: 50px;
    position: relative;
  }

  .globalnav-button:hover {
    cursor: pointer;
  }

  .globalnav-button span {
    display: inline-block;
    transition: all .4s; /* アニメーションの設定 */
    position: absolute;
    left: 14px;
    height: 3px;
    border-radius: 2px;
    background: #fff;
    width: 45%;
  }

  .globalnav-button span:nth-of-type(1) {
    top:15px;
  }

  .globalnav-button span:nth-of-type(2) {
    top:23px;
  }

  .globalnav-button span:nth-of-type(3) {
    top:31px;
  }

  /* activeクラスが付与されると線が回転して×に */

  .globalnav-button.active span:nth-of-type(1) {
      top: 18px;
      left: 18px;
      transform: translateY(6px) rotate(-45deg);
      width: 45%;
  }

  .globalnav-button.active span:nth-of-type(2) {
    opacity: 0;/* 真ん中の線は透過 */
  }

  .globalnav-button.active span:nth-of-type(3){
      top: 30px;
      left: 18px;
      transform: translateY(-6px) rotate(45deg);
      width: 45%;
  }
}
