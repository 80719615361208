.irrigation-indicator-setting-modal-container {
  padding: 1rem;
}

.irrigation-indicator-setting-modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-bottom: 4px;
  margin-bottom: 1rem;
  border-bottom: solid 1px #e5e5e5;
}

.irrigation-indicator-setting-modal-title-text {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}

.irrigation-indicator-setting-modal-close-button {
  font-size: 2rem;
  color: gray;
  cursor: pointer;
}

.irrigation-indicator-setting-modal-table {
  height: 60vh;
  overflow: scroll;
  margin-bottom: 1rem;
}

.irrigation-indicator-setting-modal-table > table {
  width: 100%;
  border-spacing: 0;
}

.irrigation-indicator-setting-modal-table > table > thead > tr > th {
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.irrigation-indicator-setting-modal-table > table > tbody > tr > td {
  height: 40px;
  border-top: 1px solid #ddd;
  padding: 0 8px 0 8px;
}

.irrigation-indicator-setting-modal-table > table > tbody > tr > td:first-child {
  text-align: left;
}

.irrigation-indicator-setting-modal-table > table > tbody > tr > td:nth-child(2) > input {
  width: 50px;
  text-align: right;
}

.irrigation-indicator-setting-modal-update-button {
  border-top: solid 1px #e5e5e5;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .irrigation-indicator-setting-modal-container {
    height: 72vh;
    overflow: scroll;
  }
}
