.daily-report-container {
  padding: 1rem;
}

.daily-report-title {
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.daily-report-title-icon {
  width: 2.7rem;
  padding-right: 0.6rem;
}

.daily-report-title-text {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
}

.daily-report-timestamp {
  position: absolute;
  right: 0;
  font-size: 1.2rem;
}

.daily-report-display-setting-button {
  padding-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
}

.daily-report-plot-area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.daily-report-daily-plot {
  width: 58%;
}

.daily-report-7day-mean-plot {
  width: 40%;
}

@media screen and (max-width: 1024px) {
  .daily-report-title {
    position: static;
    flex-direction: column;
  }

  .daily-report-timestamp {
    position: static;
    right: auto;
  }

  .daily-report-plot-area {
    flex-direction: column;
  }

  .daily-report-daily-plot {
    width: 100%;
  }

  .daily-report-7day-mean-plot {
    width: 100%;
  }
}
