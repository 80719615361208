.sp2-info-list-item-container {
  display: flex;
  align-items: center;
}

.sp2-info-list-item-icon {
  padding-right: 0.4rem;
  flex: 1;
}

.sp2-info-list-item-content {
  padding-right: 0.8rem;
  flex: 4;
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-areas:
    "name      value"
    "meter     value";
}

.sp2-info-list-item-name {
  grid-area: name;
  font-size: 1rem;
  font-weight: bold;
}

.sp2-info-list-item-meter {
  grid-area: meter;
}

.sp2-info-list-item-value {
  grid-area: value;
  align-self: flex-end;
  justify-self: right;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: right;
}

.sp2-info-list-item-value-unit {
  padding: 0;
  margin: 0;
  font-size: 0.8rem;
}
