.daily-report-table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  font-weight: bold;
  font-size: 1.6rem;
}

.daily-report-unit {
  font-size: 1.2rem;
}

.daily-report-table th,
.daily-report-table td {
  height: 6rem;
  border: solid 2px rgb(41, 171, 226);
}

.border-none {
  border: 0px none !important;
}

.daily-report-3d-ago-label,
.daily-report-2d-ago-label,
.daily-report-1d-ago-label,
.daily-report-7days-label,
.daily-report-temp-label,
.daily-report-hum-label,
.daily-report-vpd-label,
.daily-report-co2-label,
.daily-report-solar-label,
.daily-report-irrigation-label,
.daily-report-fuel-consumption-label,
.daily-report-trans-label,
.daily-report-lai-label,
.daily-report-carbon-label,
.daily-report-flowers-label,
.daily-report-fruits-label {
  color: white;
  background-color: rgb(41, 171, 226);
}

.daily-report-3d-ago-label,
.daily-report-2d-ago-label,
.daily-report-1d-ago-label {
  border-right-color: white !important;
}

.daily-report-temp-label,
.daily-report-hum-label,
.daily-report-vpd-label,
.daily-report-co2-label,
.daily-report-solar-label,
.daily-report-irrigation-label,
.daily-report-fuel-consumption-label,
.daily-report-trans-label,
.daily-report-lai-label,
.daily-report-carbon-label,
.daily-report-flowers-label,
.daily-report-fruits-label {
  border-bottom-color: white !important;
}

.daily-report-temp-daily-max-label,
.daily-report-temp-daily-max-3d-ago,
.daily-report-temp-daily-max-2d-ago,
.daily-report-temp-daily-max-1d-ago,
.daily-report-temp-daily-max-7days {
  color: red;
  border-bottom: 0px none !important;
}

.daily-report-temp-daily-mean-label,
.daily-report-temp-daily-mean-3d-ago,
.daily-report-temp-daily-mean-2d-ago,
.daily-report-temp-daily-mean-1d-ago,
.daily-report-temp-daily-mean-7days {
  border-top: 0px none !important;
  border-bottom: 0px none !important;
}

.daily-report-temp-daily-min-label,
.daily-report-temp-daily-min-3d-ago,
.daily-report-temp-daily-min-2d-ago,
.daily-report-temp-daily-min-1d-ago,
.daily-report-temp-daily-min-7days {
  color: rgb(1, 113, 188);
  border-top: 0px none !important;
}

@media screen and (max-width: 1024px) {
  .daily-report-table {
    font-size: 1rem;
  }

  .daily-report-unit {
    font-size: 1rem;
  }

  .daily-report-table th, td {
    height: 3.6rem;
  }
}
