.maximizing-photosynthetic-env-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.maximizing-photosynthetic-env-info-icon {
  display: flex;
  align-items: center;
  padding-right: 1rem;
  width: 30%;
}

.maximizing-photosynthetic-env-info-content {
  font-weight: bold;
}

.maximizing-photosynthetic-env-info-name {
  font-size: 1rem;
}

.maximizing-photosynthetic-env-info-value {
  font-size: 1.3rem;
}

.maximizing-photosynthetic-env-info-value-unit {
  font-size: 1rem;
}
