.detail-container {
  background-color: #F3F3F3;
  padding: 1rem;
}

.detail-content {
  background-color: #FFFFFF;
  margin-bottom: 1rem;
}

.detail-content:last-child {
  margin-bottom: 0;
}
