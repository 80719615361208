div, p, h1, h2, h3, h4, h5, h6, ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

.presentation {
  display: flex;
}

.globalnav-area {
  flex-basis: 12rem;
  flex-shrink: 0;
  z-index: 1;
}

.content-area {
  width: calc(100% - 12rem);
}

br.sp-br {
  display: none;
}

.ReactModal__Overlay--after-open {
  z-index: 999;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Content--after-open {
  padding: 0 !important;
}

.slick-prev:before,
.slick-next:before {
  color: #000000;
}

@media screen and (min-width: 1025px) {
  .slick-prev:before,
  .slick-next:before {
    color: #000000;
    font-size: 4rem;
  }
  
  .slick-prev,
  .slick-next {
    width: 4rem;
    height: 4rem;
  }
  
  .slick-prev {
    left: -6rem;
  }
  
  .slick-next {
    right: -6rem;
  }
  
  .slick-slide > div {
    margin: 0 5px;
  }
}

@media screen and (max-width: 1024px) {
  .presentation {
    flex-direction: column;
    position: relative;
  }

  .globalnav-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: all .5s;
    transform: translateX(-100%);
    overflow-y: scroll;
  }

  .globalnav-area.active {
    transform: translateX(0);
  }

  .content-area {
    width: 100%;
  }

  br.sp-br {
    display: block;
  }
}
