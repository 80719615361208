.daily-spatial-distribution-image-slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.daily-spatial-distribution-image-slider-title {
  padding: 0.6rem 0;
  font-size: 1.2rem;
  font-weight: bold;
}

.daily-spatial-distribution-image-slider-input-area {
  padding-bottom: 1rem;
}

.daily-spatial-distribution-image-slider-content {
  width: 100%;
  display: flex;
  justify-content: center;
}

.daily-spatial-distribution-image-slider-slider-area {
  width: 80%;
}

.daily-spatial-distribution-image-slider-slide-timestamp {
  padding: 0.5rem 0;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
}
