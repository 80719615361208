.house-container {
  padding: 1rem;
}

.house-header {
  color: #FFFFFF;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  height: 3.6rem;
}

.house-header-eggplant,
.house-header-grape {
  background-color: #B78DDB;
}

.house-header-chinese-chive,
.house-header-melon,
.house-header-spinach,
.house-header-wasabi,
.house-header-cucumber,
.house-header-green-pepper,
.house-header-green-pepper-s,
.house-header-corn {
  background-color: #459B6C;
}

.house-header-bell-pepper {
  background-color: #FF931D;
}

.house-header-tomato,
.house-header-strawberry {
  background-color: #FF777B;
}

.house-header-mandarin-orange {
  background-color: #FBB03B;
}

.house-title {
  font-size: 1.6rem;
  display: flex;
  align-items: center;
}

.house-title-icon {
  width: 10%;
  padding: 0 0.5rem;
}

.house-time {
  font-size: 1.2rem;
}

.house-content {
  background-color: #FFFFFF;
}
