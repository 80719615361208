.current-value-sp2-info-list-item-container {
  display: flex;
}

.current-value-sp2-info-list-item-icon {
  flex: 1;
  display: flex;
  align-items: center;
  padding-right: 1rem;
}

.current-value-sp2-info-list-item-content {
  flex: 3;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.current-value-sp2-info-list-item-value {
  font-size: 2rem;
}

.current-value-sp2-info-list-item-value-unit {
  font-size: 1rem;
}
