.maximizing-photosynthetic-barchart-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.maximizing-photosynthetic-barchart-chart-area {
  position: relative;
  width: 100%;
  height: 110px;
}

.maximizing-photosynthetic-barchart-vertical-bar {
  width: 2px;
  height: 100px;
  background-color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.maximizing-photosynthetic-barchart-decrease-bar-area {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(calc(-100% - 1px), -50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  color: rgb(41, 171, 226);
}

.maximizing-photosynthetic-barchart-decrease-bar {
  height: 60px;
  background-color: rgb(41, 171, 226);
}

.maximizing-photosynthetic-barchart-increase-bar-area {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(1px, -50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  color: rgb(243, 85, 85);
}

.maximizing-photosynthetic-barchart-increase-bar {
  height: 60px;
  background-color: rgb(243, 85, 85);
}
