.globalnav-menu {
  font-size: 1.4rem;
  font-weight: bold;
  position: relative;
}

.globalnav-menu p,
.globalnav-menu a {
  display: flex;
  height: 2rem;
  padding: 0.8rem;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}

.globalnav-sub-menu {
  position: absolute;
  top: 0;
  left: 100%;
  word-break: keep-all;
  width: max-content;
  background-color: #A3A3A3;
}

.globalnav-sub-menu a:visited,
.globalnav-sub-menu a:link {
  color: #FFFFFF;
}

.globalnav-sub-menu > li {
  border-bottom: 1px solid #b3b3b3;
}

@media screen and (max-width: 1024px){
  .globalnav-sub-menu {
    position: initial;
    top: initial;
    left: initial;
    width: 100%;
  }
}
