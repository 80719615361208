.house-display-order-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.house-display-order-title {
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.house-display-order-title-text {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
}

.house-display-order-update-button {
  padding-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.house-display-order-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
}

.house-display-order-list > li {
  cursor: grab;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border: 1px solid #cdcdcd;
  border-radius: 6px;
  margin-bottom: 10px;
  background-color: #f9f9fb;
  font-weight: bold;
}

.house-display-order-list > li.dragging {
  border-color: #0085f2;
  box-shadow: 0px 0px 0px 3px rgba(0,144,237,.4);
}

@media screen and (max-width: 1024px) {
  .house-display-order-title {
    position: static;
    flex-direction: column;
  }
}
