.env-info-container {
  padding: 1rem;
}

.env-info-title {
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.env-info-title-icon {
  width: 2.7rem;
  padding-right: 0.6rem;
}

.env-info-title-text {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
}

.env-info-timestamp {
  position: absolute;
  right: 0;
  font-size: 1.2rem;
}

.env-info-list-area > ul {
  display: flex;
  flex-wrap: wrap;
}

.env-info-list-area > ul > li {
  width: calc(100%/3);
}

@media screen and (max-width: 1024px) {
  .env-info-title {
    position: static;
    flex-direction: column;
  }

  .env-info-timestamp {
    position: static;
    right: auto;
  }

  .env-info-list-area > ul > li {
    width: calc(100%/2);
  }
}
