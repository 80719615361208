.image-analysis-container {
  background-color: #F3F3F3;
  padding: 1rem;
}

.image-analysis-tabs {
  display: flex;
  flex-direction: row;
}

.image-analysis-tab {
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid transparent;
  width: 50%;
  height: 50px;
  background-color: lightgray;
}

.image-analysis-active-tab {
  background-color: #FFFFFF;
}

.image-analysis-content {
  background-color: #FFFFFF;
  padding: 2rem 0;
  margin-bottom: 1rem;
}
